import React from 'react';
import { isMobile } from 'react-device-detect';

import Layout from '../templates/layout-main';
import SEO from '../atoms/seo';

import globalData from '../../../../content/siteMetadata.json';

class IndexPage extends React.Component {

  state = {
    data: globalData
  };

  render() {
    return (
      this.state.data ?
        <Layout isMobile={isMobile} data={this.state.data}>
          <SEO title={this.state.data.title} />
        </Layout>
        :
        <div>Loading</div>
    );
  }
};

export default IndexPage;
